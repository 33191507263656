import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/rf/rf.png"
import rf1 from "../../../assets/images/portfolio_items/rf/rf1.png"
import rf2 from "../../../assets/images/portfolio_items/rf/rf2.png"
import rf3 from "../../../assets/images/portfolio_items/rf/rf3.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | Credit Suisse - Mobile Marketing Suisse'}
    description="An innovative mobile marketing experience for Credit Suisse: discover it now on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/credit-suisse'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="Credit Suisse, ICON Worldwide, mobile marketing esperience, portfolio" title="ICON Worldwide portfolio, mobile marketing esperience, Credit Suisse"/>
            <div className="title"><h1>Credit Suisse<span></span></h1>
            <h2>An innovative mobile marketing experience</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>As Roger Federer competed for a record eighth Wimbledon championship, Credit Suisse wanted to create a platform for fans to cheer him on.
         We created an integrated website, facebook fan page and mobile experience allowing fans to post their photos as messaged of support.</p>
        <div className="portfolio-images" id="long-page">
            <img src={rf1} alt="Credit Suisse, ICON Worldwide, mobile marketing esperience, portfolio" title="ICON Worldwide portfolio, mobile marketing esperience, Credit Suisse"/>
            <img src={rf3} alt="Credit Suisse, ICON Worldwide, mobile marketing esperience, portfolio" title="ICON Worldwide portfolio, mobile marketing esperience, Credit Suisse"/>
            <img src={rf2} alt="Credit Suisse, ICON Worldwide, mobile marketing esperience, portfolio" title="ICON Worldwide portfolio, mobile marketing esperience, Credit Suisse"/>
        </div>
        <WorkFooter previous="nuyu" next="qiio"/>
        </div>
    </div>
</div>